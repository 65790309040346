<template>
  <div style="text-align: justify">
    <p class="mb-4">
      For each of the three reactions listed below, write the balanced i) molecular, ii) total
      ionic, and iii) net ionic equations. Consult the Appendix: Solubility Guidelines for Common
      Ionic Compounds in Water in your lab manuals to help you answer this question.
    </p>

    <v-simple-table style="margin-bottom: 20px">
      <tr>
        <td style="width: 20px">
          <stemble-latex content="$\text{a)}$" />
        </td>
        <td style="vertical-align: middle; height: 60px" class="mb-1">
          <stemble-latex
            content="$\text{Aqueous lead(II) nitrate plus aqueous potassium iodide}$"
          />
        </td>
      </tr>

      <tr>
        <td style="width: 20px">
          <stemble-latex content="$\text{i)}$" />
        </td>
        <td style="vertical-align: middle; height: 60px">
          <chemical-notation-input v-model="inputs.balancedRxn1" :show-note="false" class="my-1" />
        </td>
      </tr>

      <tr>
        <td style="width: 20px">
          <stemble-latex content="$\text{ii)}$" />
        </td>
        <td style="vertical-align: middle; height: 60px">
          <chemical-notation-input
            v-model="inputs.totalIonicRxn1"
            :show-note="false"
            class="my-1"
          />
        </td>
      </tr>

      <tr>
        <td style="width: 20px">
          <stemble-latex content="$\text{iii)}$" />
        </td>
        <td style="vertical-align: middle; height: 60px">
          <chemical-notation-input v-model="inputs.netIonicRxn1" :show-note="false" class="my-1" />
        </td>
      </tr>

      <tr>
        <td style="width: 20px">
          <stemble-latex content="$\text{b)}$" />
        </td>
        <td style="vertical-align: middle; height: 60px" class="mt-3 mb-1">
          <stemble-latex content="$\text{Aluminum metal plus aqueous zinc chloride}$" />
        </td>
      </tr>

      <tr>
        <td style="width: 20px">
          <stemble-latex content="$\text{i)}$" />
        </td>
        <td style="vertical-align: middle; height: 60px">
          <chemical-notation-input v-model="inputs.balancedRxn2" :show-note="false" class="my-1" />
        </td>
      </tr>

      <tr>
        <td style="width: 20px">
          <stemble-latex content="$\text{ii)}$" />
        </td>
        <td style="vertical-align: middle; height: 60px">
          <chemical-notation-input
            v-model="inputs.totalIonicRxn2"
            :show-note="false"
            class="my-1"
          />
        </td>
      </tr>

      <tr>
        <td style="width: 20px">
          <stemble-latex content="$\text{iii)}$" />
        </td>
        <td style="vertical-align: middle; height: 60px">
          <chemical-notation-input v-model="inputs.netIonicRxn2" :show-note="false" class="my-1" />
        </td>
      </tr>

      <tr>
        <td style="width: 20px">
          <stemble-latex content="$\text{c)}$" />
        </td>
        <td style="vertical-align: middle; height: 60px" class="mt-3 mb-1">
          <stemble-latex
            content="$\text{Aqueous caesium iodide plus an aqueous solution of bromine}$"
          />
        </td>
      </tr>

      <tr>
        <td style="width: 20px">
          <stemble-latex content="$\text{i)}$" />
        </td>
        <td style="vertical-align: middle; height: 60px">
          <chemical-notation-input v-model="inputs.balancedRxn3" :show-note="false" class="my-1" />
        </td>
      </tr>

      <tr>
        <td style="width: 20px">
          <stemble-latex content="$\text{ii)}$" />
        </td>
        <td style="vertical-align: middle; height: 60px">
          <chemical-notation-input
            v-model="inputs.totalIonicRxn3"
            :show-note="false"
            class="my-1"
          />
        </td>
      </tr>

      <tr>
        <td style="width: 20px">
          <stemble-latex content="$\text{iii)}$" />
        </td>
        <td style="vertical-align: middle; height: 60px">
          <chemical-notation-input v-model="inputs.netIonicRxn3" :show-note="false" class="my-1" />
        </td>
      </tr>
    </v-simple-table>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput.vue';

export default {
  name: 'UPEIReactionsPreLab3',
  components: {
    ChemicalNotationInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        balancedRxn1: null,
        totalIonicRxn1: null,
        netIonicRxn1: null,
        balancedRxn2: null,
        totalIonicRxn2: null,
        netIonicRxn2: null,
        balancedRxn3: null,
        totalIonicRxn3: null,
        netIonicRxn3: null,
      },
    };
  },
};
</script>
